import { DynamicImage, PrimaryButton, Seo, Text } from 'components';
import Header from 'components/Header';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import 'swiper/swiper.min.css';
import LoadingBar from './components/LoadingBar';
import { useRouter } from 'apis/history';
import { Quiz } from 'types/quiz';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import QuestionMark from 'assets/icons/question-mark.svg';
import PopupX from 'assets/icons/popup-x.svg';
import { normalizeStates } from 'utils/localization';

SwiperCore.use([Autoplay]);

const Finalizing = () => {
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const data = useQuizData('finalizing');
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const { countdownDiffInMS } = useFirstVisitDate();
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;
  const { goToEmail, goToResults } = useRouter();
  const { code, user, geolocation, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );

  const { isMobile } = useQuery();
  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
    email: user?.email,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isPaused) {
        switch (currentStep) {
          case 0:
            setProgress1(prevProgress => {
              const newProgress = prevProgress + 1;
              if (newProgress >= 100) {
                clearInterval(intervalId);
                setTimeout(() => {
                  setCurrentStep(1);
                }, 400);
              }

              return newProgress;
            });
            break;
          case 1:
            setProgress2(prevProgress => {
              const newProgress =
                prevProgress +
                (prevProgress >= 64 && prevProgress <= 74 ? 0.3 : 1);

              if (newProgress >= 100) {
                clearInterval(intervalId);
                setTimeout(() => {
                  setCurrentStep(2);
                }, 400);
              }

              if (prevProgress < 75 && newProgress >= 75) {
                setShowPopup(true);
                setIsPaused(true);
              }

              return newProgress;
            });
            break;
          case 2:
            setProgress3(prevProgress => {
              const newProgress =
                prevProgress +
                (prevProgress >= 29 && prevProgress <= 39 ? 0.3 : 1);

              if (newProgress >= 100) {
                clearInterval(intervalId);
                setCurrentStep(3);
                handleNavigationOnCounterEnd();
              }

              if (prevProgress < 40 && newProgress >= 40) {
                setShowPopup(true);
                setIsPaused(true);
              }
              return newProgress;
            });
            break;
          default:
            break;
        }
      }
    }, 60);

    return () => clearInterval(intervalId);
  }, [currentStep, isPaused]);

  const handleNavigationOnCounterEnd = () => {
    setTimeout(() => {
      if (variant === 'funnel-email' || variant === 'email-first') {
        return goToEmail();
      }
      goToResults();
    }, 1500);
  };

  if (!data) {
    return null;
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsPaused(false);
  };

  return (
    <>
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}

      <Header
        logoVariant="center"
        sticky={false}
        color="light0"
        hasDivider={true}
      />
      <Container>
        {data.title && <Title>{data.title}</Title>}
        <LoaderMain>
          <MainLoaderContainer>
            <LoaderTextContainer>
              <LoaderText
                dangerouslySetInnerHTML={{ __html: data.sections[0].text }}
              />
              <LoaderPercent color={data.sections[0].bgColorActive}>
                {Math.round(progress1)}%
              </LoaderPercent>
            </LoaderTextContainer>
            <LoaderDiv bgColor={data.sections[0].bgColor}>
              <InnerLoaderDiv
                bgColor={data.sections[0].bgColorActive}
                width={progress1}
              />
            </LoaderDiv>
          </MainLoaderContainer>
          <MainLoaderContainer>
            <LoaderTextContainer>
              <LoaderText
                dangerouslySetInnerHTML={{ __html: data.sections[1].text }}
              />
              <LoaderPercent color={data.sections[1].bgColorActive}>
                {Math.round(progress2)}%
              </LoaderPercent>
            </LoaderTextContainer>
            <LoaderDiv bgColor={data.sections[1].bgColor}>
              <InnerLoaderDiv
                bgColor={data.sections[1].bgColorActive}
                width={progress2}
              />
            </LoaderDiv>
          </MainLoaderContainer>
          <MainLoaderContainer>
            <LoaderTextContainer>
              <LoaderText
                dangerouslySetInnerHTML={{ __html: data.sections[2].text }}
              />
              <LoaderPercent color={data.sections[2].bgColorActive}>
                {Math.round(progress3)}%
              </LoaderPercent>
            </LoaderTextContainer>
            <LoaderDiv bgColor={data.sections[2].bgColor}>
              <InnerLoaderDiv
                bgColor={data.sections[2].bgColorActive}
                width={progress3}
              />
            </LoaderDiv>
          </MainLoaderContainer>
        </LoaderMain>
        <Disclaimer>{data.disclaimer}</Disclaimer>
        {showPopup && (
          <Popup>
            <PopupInner>
              <PopupContent>
                <XContainer>
                  <PopupX onClick={handleClosePopup} />
                </XContainer>

                <QuestionMark />
                <PopupTitle>
                  {progress3 > 1
                    ? 'Do you have a significant life event coming up soon?'
                    : 'Do you have unresolved romantic feelings about someone?'}
                </PopupTitle>
                <PopupButtons>
                  <NoButton onClick={handleClosePopup}>No</NoButton>
                  <YesButton onClick={handleClosePopup}>Yes</YesButton>
                </PopupButtons>
              </PopupContent>
            </PopupInner>
          </Popup>
        )}
      </Container>
    </>
  );
};

export default Finalizing;

const PopupTitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.15888rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.68038rem; /* 145% */
  padding: 2rem 0 1.25rem;
  @media ${tablet} {
    padding: 2.125rem 0 1.25rem;
  }
`;

const PopupButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.75rem;
  @media ${tablet} {
    gap: 0.8125rem;
  }
`;

const XContainer = styled.div`
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  svg {
    width: 1.625rem;
    height: 1.75rem;
    cursor: pointer;
  }
  @media ${tablet} {
    padding-bottom: 0;
  }
`;

const NoButton = styled.div`
  display: flex;
  height: 3.375rem;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  border: 1px solid #4d4d4d;
  background: #fff;
  color: #4d4d4d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.68038rem;
  width: 100%;
  cursor: pointer;
  @media ${tablet} {
    height: 3rem;
    padding: 0.375rem 0.625rem;
  }
`;
const YesButton = styled.div`
  display: flex;
  height: 3.375rem;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 6.25rem;
  border-radius: 6.25rem;
  background: #f7c800;
  border: 1px solid #f7c800;
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.68038rem; /* 168.039% */
  width: 100%;
  cursor: pointer;
  @media ${tablet} {
    height: 3rem;
    padding: 0.375rem 0.625rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.0625rem;
  width: 100%;
  max-width: 28.75rem;

  @media ${tablet} {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.8125rem;
  }
`;

const Disclaimer = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  display: flex;
  max-width: 28.75rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem;
  height: calc(100vh - 68px);
  @media ${tablet} {
    align-items: center;
    padding: 1.5rem 1rem;
  }
`;

const LoaderMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 28.75rem;
  padding: 2.5rem 0;
  @media ${tablet} {
    padding: 2.5rem 0 3.5rem;
  }
`;

const LoaderDiv = styled.div<{ bgColor: string }>`
  max-width: 28.75rem;
  width: 100%;
  height: 1rem;
  border-radius: 6.25rem;
  background: ${({ bgColor }) => bgColor};
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InnerLoaderDiv = styled.div.attrs(props => ({
  style: {
    background: props.bgColor,
    width: `${props.width}%`,
  },
}))`
  position: absolute;
  height: 1rem;
  transition: width 0.1s ease-in-out;
`;

const LoaderText = styled(Text)`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 3;
  span {
    margin-right: 8px;
  }
`;

const LoaderPercent = styled(LoaderText)<{ color: string }>`
  letter-spacing: -0.04156rem;
  color: ${({ color }) => color && color};
`;

const MainLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LoaderTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Popup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 23, 54, 0.7) 0%,
      rgba(0, 23, 54, 0.7) 100%
    );
    backdrop-filter: blur(5px);
    pointer-events: none;
    z-index: 1;
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const PopupInner = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 2.375rem;
  width: 28.75rem;
  border-radius: 0.5rem;
  z-index: 2;
  background-color: white;
  @media ${tablet} {
    width: 21.1875rem;
    padding: 1.12rem 1.38rem;
  }
`;
